<template>
  <div id="merchant-page">
    <div class="flex items-center page-title py-2 px-4 mb-5">
      <feather-icon icon="AlertCircleIcon" svgClasses="h-6 w-6 text-blue-100" class="pr-5"/>
      <span class="ml-2 text-sm font-semibold">
        <p>
          {{$t('For help and guidance on how to pack your orders, check out our')}}
          <a target="_blank" :href="`https://support.shipblu.com/${$i18n.locale}/support/solutions/articles/154000189336-packaging-guidelines`" class="text-blue-100 underline">{{$t('Packaging Guidelines')}}</a> {{$t('our')}}
        </p>
      </span>
    </div>
    <div class="flex mb-6 sm:justify-start justify-center" style="border-bottom: 1px solid #C7C7C7;">
      <div :class="$route.query.tab === tab.value ? 'supplies-active-tab' : 'text-darkgray'" class="py-5 px-8 cursor-pointer" v-for="tab in tabs" :key="tab.index" @click="changeTabFilter(tab.value)">
        <p class="font-semibold leading-tight">{{$t(tab.name)}}</p>
      </div>
    </div>
    <div class="relative" v-if="activeTab === 0">
      <supplies-items :merchant="merchant" :merchantStatus="merchantStatus" :packagesSize="packagesSize" :typeFilter="typeFilter" @changeTabFilterSearch="changeTabFilterSearch" @discount_code="discount_code = $event" @supplyRequest="supplyRequest"></supplies-items>
    </div>
    <div v-else>
      <supplies-orders></supplies-orders>
    </div>
    <select-locations :paymentTypes="paymentTypes" @paymentTypes="paymentTypes = $event" :payment="payment" @payment="payment = $event" :selectLocationsModal="selectLocationsModal" @selectLocationsModal="selectLocationsModal = $event" @pickupPoint="pickupPoint = $event" @checkOut="checkOut"></select-locations>
    <success-massege :totalPrice="totalPrice" :successMassegeModal="successMassegeModal" @successMassegeModal="successMassegeModal = $event"></success-massege>
    <add-merchant-profile-info :addMerchantInfo="addMerchantInfo" @addMerchantInfo="addMerchantInfo = $event" @merchantUpdated="merchantUpdated = $event" @selected="selected = $event" />
  </div>
</template>

<script>
import {sendRequest} from '../../http/axios/requestHelper'
import SuppliesOrders from './SuppliesOrders.vue'
import SuppliesItems from './SuppliesItems.vue'
import SelectLocations from './SelectLocations.vue'
import successMassege from './successMassege.vue'
import i18nData from '../../i18n/i18nData' 
import common from '../../assets/utils/common'
import AddMerchantProfileInfo from './components/AddMerchantProfileInfo.vue'

export default {
  data () {
    return {
      totalPrice: 0,
      pickupPoint: {},
      tabs: [
        {
          name: 'Supplies Shop',
          value: 'supplies-shop'
        },
        {
          name: 'Supplies Orders',
          value: 'supplies-orders'
        }
      ],
      tabsDic: {'0': 'supplies-shop', '1': 'supplies-orders'},
      tabsNameDic: {'supplies-shop': 0, 'supplies-orders': 1},
      searchTab: ['All', 'Flyer', 'Box', 'other'],
      searchTabsDic: {'All': 'All', 'flyer': 'flyer', 'box': 'box', 'other': 'other'},
      activeTab: 0,
      supplies: [],
      supply: {},
      packagesSize: [],
      packagesDic: {},
      selectLocationsModal: false,
      successMassegeModal: false,
      typeFilter: 'all',
      merchantStatus: '',
      merchant: {},
      addMerchantInfo: false,
      merchantUpdated: false,
      discount_code: '',
      payment: {},
      paymentTypes: [
        {
          name: 'ShipBlu Balance',
          value: 'cash',
          checked: false
        },
        {
          name: 'Credit Card',
          value: 'card',
          checked: false
        }
      ],
      userMerchant: {},
      payableID: 0
    }
  },
  watch: {
    '$route.params.lang' () {
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-supplies-shop`,
        query: {
          tab: this.tabsDic[this.activeTab],
          page: 1,
          type: this.typeFilter
        }
      }).catch(() => {})
    },
    '$route.query.tab' () {
      this.activeTab = this.tabsNameDic[this.$route.query.tab]
      this.typeFilter = 'all'
    },
    activeTab () {
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-supplies-shop`,
        query: {
          tab: this.tabsDic[this.activeTab],
          page: 1,
          type: 'all'
        }
      }).catch(() => {})
    },
    merchantUpdated () {
      const merchantData = common.checkMerchantInLocalStorage(this)
      merchantData.then(results => {
        this.merchant = results.merchantData
      })
    },
    selectLocationsModal () {
      this.payment = {}
      this.paymentTypes.forEach(item => { item.checked = false })
    }
  },
  methods: {
    supplyRequest () {
      if (this.merchant.is_self_signup === true && this.merchant.name === 'Entrepreneur Merchant') {
        this.addMerchantInfo = true
      } else {
        this.selectLocationsModal = true
      }
    },
    changeTabFilterSearch (type) {
      this.typeFilter = type
      this.$router.push({
        query: {
          tab: this.tabsDic[this.activeTab],
          page: 1,
          filter: this.filters,
          type: this.typeFilter
        }
      }).catch(() => {})
    },
    changeTabFilter (tab) {
      this.activeTab = this.tabsNameDic[tab]
      this.$router.push({
        query: {
          tab: this.tabsDic[this.activeTab],
          page: this.activeTab,
          filter: this.filters,
          type: this.typeFilter
        }
      }).catch(() => {})
      
    },
    loadPackagesSize () {
      sendRequest(false, false, this, 'api/v1/package-sizes/', 'get', null, true,
        (response) => {
          const data = response.data.filter(item => item.id !== 9 && item.id !== 10)
          this.packagesSize = data
          const time = new Date()
          const PackageSizesDataTime = {data, time}
          localStorage.setItem('packages', JSON.stringify(PackageSizesDataTime))
          this.managePackageSize(response.data.filter(item => item.id !== 9 && item.id !== 10))
          localStorage.setItem('cartItems', JSON.stringify(this.packagesSize))
        }
      )
    },
    managePackageSize () {
      if (localStorage.getItem('cartItems')) {
        this.packagesSize = JSON.parse(localStorage.getItem('cartItems'))
      } else {
        this.packagesSize.forEach(element => {
          element.count = 0
        })
      }
      this.packagesSize.forEach(packageSize => {
        this.packagesDic[packageSize.id] = packageSize.short_code
      })
    },
    checkOut () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const packages = []
          this.packagesSize.forEach(element => {
            packages.push({
              package_size: element.id,
              count: Number(element.count)  
            })
          })
          const dataSend = {
            supplies_items: packages.filter(item => item.count > 0),
            pickup_point : this.pickupPoint.id
          }
          if (this.discount_code) {
            dataSend.discount_code = this.discount_code
          }
          this.totalPrice = this.packagesSize.map(item => item.count * item.unit_price).reduce((firstPackage, secondPackage) => firstPackage + secondPackage, 0)
          sendRequest(false, false, this, 'api/v1/supplies/', 'post', dataSend, true,
            (data) => {
              let payment = {
                payment_request: data.data.payment_request.id
              }
              if (this.payment.pay_using.value === 'card') {
                payment = {
                  ...payment,
                  billing_data: {
                    name: `${this.userMerchant.first_name} ${this.userMerchant.last_name}`,
                    email: this.userMerchant.email,
                    phone_number: `+2${this.userMerchant.phone_number}`
                  },
                  pay_using: this.payment.pay_using.value
                }
              }        
              sendRequest(false, false, this, `api/v1/payments/${this.payment.pay_using.value === 'card' ? 'xpay' : 'shipblu-cash'}/pay/`, 'post', payment, true,
                (response) => {
                  if (this.payment.pay_using.value === 'card') {
                    const routeData = this.$router.resolve({
                      name: 'merchant-supplies-payment',
                      params: {
                        'transactionID': response.data.id
                      }
                    })
                    this.paymentTypes.forEach(item => { item.checked = false })
                    window.open(routeData.href, '_blank')
                  } else {
                    this.$emit('loadSupplies')
                    this.$vs.notify({
                      color: response.data.status === 'failed' ? 'danger' : 'success',
                      title: response.data.status.toUpperCase(),
                      text: response.data.message,
                      position: 'top-center'
                    })
                  }
                  this.$vs.notify({
                    color:'success',
                    title:i18nData[this.$i18n.locale]['Success'],
                    text: 'Your Order has been confirmed',
                    position: 'top-center'
                  })
                  this.$vs.loading.close('#pay-btn-loading > .con-vs-loading')
                  this.selectLocationsModal = false
                }, (error) => {
                  common.notifyResponseError(this, error)
                  this.$vs.loading.close('#pay-btn-loading > .con-vs-loading')
                }
              )
              this.packagesSize.forEach(element => {
                element.count = 0
              })
              localStorage.setItem('cartItems', JSON.stringify(this.packagesSize))
            }
          )
        }
      })
    }
  },
  created () {
    this.activeTab = this.$route.query.tab ? this.tabsNameDic[this.$route.query.tab] : '0'
    this.typeFilter = this.$route.query.type ? this.searchTabsDic[this.$route.query.type] : 'all'
    this.loadPackagesSize()
    const merchantData = common.checkMerchantInLocalStorage(this)
    let user = {}
    merchantData.then(results => {
      user = results.merchantData
      this.userMerchant = results.userMerchantData
      this.merchantStatus = user.status
      this.merchant = user
    })
  },
  components: {
    SuppliesOrders,
    SuppliesItems,
    SelectLocations,
    successMassege,
    AddMerchantProfileInfo
  }
}
</script>

<style lang="scss">
.supplies-active-tab {
  background: #EDF4FB;
  color: #1C5BFE;
  border-radius: 8px 8px 0px 0px;
  border-bottom: 3px solid #1C5BFE;
}
.supplies-items {
  height: calc(100vh - 280px);
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
.supply-item {
  background: rgba(243, 249, 255, 0.68);
  border-radius: 10px 10px 0px 0px;
  .size {
    background: rgba(28, 91, 254, 0.09);
    border-radius: 5px;
    position: absolute;
    left: 10.5px;
    top: 10.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    color: #0A3266;
    font-weight: 600;
    font-size: 14px;
  }
}
.supply-plus-button{
  border-radius: 5px !important;
  background: #0A3266 !important;
  max-width: 30px;
  max-height: 30px;
}
.input-shop {
  position: relative;
  background: #FFFFFF;
  border: 1px solid #CACACA;
  border-radius: 5px;
  height: 25px;
  &.invalid {
    border: 1px solid #FE8383;
     input[type="number"]::-webkit-inner-spin-button {
      border-left: 1px solid #FE8383;
    }
  }
  .vs-input-number--input{
    width: 50px !important;
    font-size: .875rem !important;
  }
  .btn-less, .btn-plus{
    display: none;
  }
  &:before {
    content: "";
    position: absolute;
    bottom: 2px;
    right: 3px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid #E5E5E5;
    pointer-events: none;
  }
  &:after {
    content: "";
    position: absolute;
    top: 2px;
    right: 3px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 7px solid #E5E5E5;
    pointer-events: none;
  }
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    height: 25px;
    width: 12px;
    opacity: 1;
    border-left: 1px solid #d8d8d8;
    border-radius: 5px;
    cursor: pointer;
  }
}
.supplies-card {
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(131, 131, 131, 0.22);
  border-radius: 10px;
  border: 1px solid #F3F9FF;
}
.active-card{
  border: 1px solid #1C5BFE;
  box-shadow: 0px 0px 4px #1C5BFE;
}
.checkout-cart {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 280px;
  border-radius: 10px 10px 0px 0px;
  .cart-body {
    height: calc(100% - 260px);
    position: absolute;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .total {
    background: #F1F8FF;
    box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.25);
    border-radius: 10px 10px 0px 0px;
  }
  .item {
    background: #FFFFFF;
    box-shadow: 1px 1px 4px rgba(28, 91, 254, 0.22);
    border-radius: 10px;
  }
}
.input-order {
  border: none;
  padding: 8px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15) !important;
  border-radius: 4px;
  color: #1C5BFE;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  font-family: inherit;
  &::placeholder {
    color: #9DADC2 !important;
  }
}
@media (max-width: 767px) {
  .checkout-cart {
    top: unset;
    bottom: -117px;
    width: 100%;
    z-index: 2;
  }
  .supplies-items {
    height: calc(100vh - 390px);
  }
}
</style>