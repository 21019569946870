<template>
  <shipblu-prompt
    class="shipment-modal"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="successMassegeModal"
    :title="$t('')"
    :buttons-hidden="true">
    <div class="block mb-2 p-6 text-center">
      <img height="182" width="182" src="../../assets/images/pages/success.gif"/>
      <p class="font-semibold text-xl">{{ $t('Your Order has been confirmed') }}</p>
      <p class="font-medium text-base text-grey" v-if="totalPrice > 0">{{ $t('The order total amount will be added to your financial  statement') }}</p>
    </div>
    <div class="grid grid-cols-1 gap-4 mt-4 items-center justify-content w-2/3 m-auto">
      <button @click="closeModal()" class="active-btn btn text-base">{{ $t('Done') }}</button>
    </div>
  </shipblu-prompt>
</template>
<script>
import ShipbluPrompt from '../../layouts/components/ShipBluPrompt.vue'

export default {
  props: ['successMassegeModal', 'totalPrice'],
  data () {
    return {
    }
  },
  components: {
    ShipbluPrompt
  },
  methods: {
    closeModal () {
      this.$emit('successMassegeModal', false)
    }
  }
}
</script>

<style lang='scss'>
</style>