<template>
  <div class="relative">
    <!-- SUPPLIES ITEMS -->
    <div class="supplies-items px-1" :style="isAndroid ? 'width: 100%' : 'width: calc(100% - 300px);'">
      <div class="page-title flex p-2 mb-5 gap-3">
        <div :class="$route.query.type === tab.value ? 'bg-primary text-white font-medium' : 'bg-white text-darkgray'" class="py-2 px-3 cursor-pointer rounded-md hover:shadow-drop"
          v-for="tab in statusFilters" :key="tab.index" @click="$emit('changeTabFilterSearch', tab.value)">
          <div class="text-sm">{{$t(tab.name)}}</div>
        </div>
      </div>

      <!-- FLYERS -->
      <div v-if="allFlag || flayerFlag">
        <p class="text-xl font-medium text-darkblue">{{$t('Flyers')}}</p>
        <div class="grid xl:grid-cols-4 lg:grid-cols-3 grid-cols-2 gap-4 mt-3 mb-2">
          <div v-for="(item, index) in packagesSize.filter(item => item.type === 'flyer')" :key="index">
            <div class="relative h-full supplies-card" :class="item.count === 0 ? '' : 'active-card'" :style="item.short_code === 'OSZ' ? 'display: none;' : ''">
              <div class="p-3 text-center supply-item">
                <span class="size">{{item.short_code.split('-')[0]}}</span>
                <img height="77" width="68" src="../../assets/images/pages/flyer.svg"/>
              </div>
              <div class="px-3 py-2">
                <p v-if="item.unit_price > 0" class="text-primary text-sm font-medium">{{item.unit_price ? Number(item.unit_price).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' EGP': '0.00' + ' EGP'}}</p>
                <p v-else class="text-primary text-sm font-medium">{{$t('Free')}}</p>
                <div class="flex justify-between items-center gap-1 mt-1">
                  <div>
                    <p class="text-darkblue text-sm font-medium">{{$t(item.name.split(' (')[0])}}</p>
                    <p class="text-darkgray text-xs">{{item.name.split('(')[1] ? $t(item.name.split('(')[1].slice(0, -1)) : ''}}</p>
                  </div>
                  <vs-button
                    color="primary"
                    icon-pack="feather"
                    icon="icon-plus"
                    size="large"
                    class="supply-plus-button"
                    v-if="item.count === 0"
                    @click="addOne(item)"
                  >
                  </vs-button>
                  <vs-input-number v-else min="0" v-validate="'required|numeric'" :name="`quantity-f-${index}`" v-model.number="item.count" :value="0" class="input-shop" :class="errors.has(`quantity-f-${index}`) ? 'invalid' : ''"/>
                </div>
                <span class="text-danger text-sm hidden" v-show="errors.has(`quantity-f-${index}`)">{{ errors.first(`quantity-f-${index}`) ? errors.first(`quantity-f-${index}`).split(`-${index}`)[0] + errors.first(`quantity-f-${index}`).split(`-${index}`)[1]  : '' }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- BOXES -->
      <div v-if="allFlag || bosxFlag">
        <p class="text-xl font-medium text-darkblue">{{$t('Boxes')}}</p>
        <div class="grid xl:grid-cols-4 lg:grid-cols-3 grid-cols-2 gap-4 mt-3 mb-6">
          <div v-for="(item, index) in packagesSize.filter(item => item.type === 'box')" :key="index">
            <div class="relative h-full supplies-card" :class="item.count === 0 ? '' : 'active-card'" :style="item.short_code === 'OSZ' ? 'display : none;' : ''">
              <div class="p-3 text-center supply-item">
                <span class="size">{{item.short_code.split('-')[0]}}</span>
                <img height="73" width="64" src="../../assets/images/pages/boxes.png"/>
              </div>
              <div class="px-3 py-2">
                <p v-if="item.unit_price > 0" class="text-primary text-sm font-medium">{{item.unit_price ? Number(item.unit_price).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) + $t(' EGP'): '0.00' + $t(' EGP')}}</p>
                <p v-else class="text-primary text-sm font-medium">{{$t('Free')}}</p>
                <div class="flex justify-between items-center gap-1 mt-1">
                  <div>
                    <p class="text-darkblue text-sm font-medium">{{$t(item.name.split(' (')[0])}}</p>
                    <p class="text-darkgray text-xs">{{item.name.split('(')[1] ? $t(item.name.split('(')[1].slice(0, -1)) : ''}}</p>
                  </div>
                  <vs-button
                    color="primary"
                    icon-pack="feather"
                    icon="icon-plus"
                    size="large"
                    class="supply-plus-button"
                    v-if="item.count === 0"
                    @click="addOne(item)"
                  >
                  </vs-button>
                  <vs-input-number v-else min="0" v-validate="'required|numeric'" :name="`quantity-b-${index}`" v-model.number="item.count" :value="0" class="input-shop" :class="errors.has(`quantity-b-${index}`) ? 'invalid' : ''"/>
                </div>
                <span class="text-danger text-sm hidden" v-show="errors.has(`quantity-b-${index}`)">{{ errors.first(`quantity-b-${index}`) ? errors.first(`quantity-b-${index}`).split(`-${index}`)[0] + errors.first(`quantity-b-${index}`).split(`-${index}`)[1]  : '' }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- OTHER ITEMS -->
      <div v-if="allFlag || otherFlag">
        <p class="text-xl font-medium text-darkblue">{{$t('Other Items')}}</p>
        <div class="grid xl:grid-cols-4 lg:grid-cols-3 grid-cols-2 gap-4 mt-3 md:mb-1 mb-6">
          <div v-for="(item, index) in packagesSize.filter(item => item.type === 'other')" :key="index">
            <div class="relative h-full supplies-card" :class="item.count === 0 ? '' : 'active-card'" :style="item.short_code === 'OSZ' ? 'display : none;' : ''">
              <div class="p-3 text-center supply-item">
                <img height="73" :src="importImage(index)"/>
              </div>
              <div class="px-3 py-2">
                <p v-if="item.unit_price > 0" class="text-primary text-sm font-medium">{{item.unit_price ? Number(item.unit_price).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) + $t(' EGP'): '0.00' + $t(' EGP')}}</p>
                <p v-else class="text-primary text-sm font-medium">{{$t('Free')}}</p>
                <div class="flex justify-between items-center gap-1 mt-1">
                  <div>
                    <p class="text-darkblue text-sm font-medium">{{$t(item.name)}}</p>
                    <p class="text-darkgray text-xs">{{$t(item.description)}}</p>
                  </div>
                  <vs-button
                    color="primary"
                    icon-pack="feather"
                    icon="icon-plus"
                    size="large"
                    class="supply-plus-button"
                    v-if="item.count === 0"
                    @click="addOne(item)"
                  >
                  </vs-button>
                  <vs-input-number v-else min="0" v-validate="'required|numeric'" :name="`quantity-o-${index}`" v-model.number="item.count" :value="0" class="input-shop" :class="errors.has(`quantity-o-${index}`) ? 'invalid' : ''"/>
                </div>
                <span class="text-danger text-sm hidden" v-show="errors.has(`quantity-o-${index}`)">{{ errors.first(`quantity-o-${index}`) ? errors.first(`quantity-o-${index}`).split(`-${index}`)[0] + errors.first(`quantity-o-${index}`).split(`-${index}`)[1]  : '' }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- CART -->
    <div class="supplies-card checkout-cart" :class="!isAndroid ? 'h-full' : ''" :style="flag || !isAndroid ? 'height: 470px;' : 'height: 0px;'">
      <div class="relative h-full">
        <div :class="flag || !isAndroid ? 'block' : 'hidden'"> 
          <div class="py-3 px-4 mt-4 mx-4 mb-3 text-lg font-semibold text-black page-title flex justify-between items-center">
            <p class="font-semibold text-lg text-dark leading-tight">{{$t('Cart')}}</p>
            <feather-icon @click="flag = !flag" :class="isAndroid ? 'block cursor-pointer w-5 h-5 text-darkgray' : 'hidden'" icon="XSquareIcon" svgClasses="w-5 h-5"/>
          </div>
          <div v-if="packagesSize.filter(item => item.count > 0).length === 0" class="cart-body py-1 px-4 w-full">
            <div class="my-5 text-black text-center">
              <img height="182" width="182" src="../../assets/images/pages/supply-empty.gif"/>
              <p class="text-xl font-semibold">{{$t('Your cart is empty')}}</p>
              <p class="text-sm font-medium">{{$t('Add items to your cart')}}</p>
            </div>
          </div>
          <div v-else class="cart-body py-1 px-4 w-full">
            <div v-for="item in packagesSize.filter(item => item.count > 0)" :key="item.index">
              <div class="p-2 grid grid-cols-8 gap-2 justify-between mb-3 item">
                <div class="flex items-center">
                  <img height="29" width="26" src="../../assets/images/pages/flyer.svg" v-if="item.type === 'flyer'"/>
                  <img height="29" width="26" src="../../assets/images/pages/boxes.png" v-if="item.type === 'box'"/>
                  <img height="29" width="26" src="../../assets/images/pages/other0.png" v-if="item.id === 9"/>
                  <img height="29" width="26" src="../../assets/images/pages/other1.png" v-if="item.id === 10"/>
                  <img height="29" width="26" src="../../assets/images/pages/other2.png" v-if="item.id === 11"/>
                  <img height="29" width="26" src="../../assets/images/pages/other3.png" v-if="item.id === 12"/>
                  <img height="29" width="26" src="../../assets/images/pages/other4.png" v-if="item.id === 13"/>
                </div>
                <div class="col-span-3">
                  <p class="text-sm" style="color: #1C5BFE!important;">{{item.name.split('(')[0]}}</p>
                  <p class="text-xs" >{{item.name.split('(')[1] ? item.name.split('(')[1].slice(0, -1) : ''}}</p>
                </div>
                <div class="items-center col-span-3 grid grid-cols-4">
                  <div class="col-span-2">
                    <p class="text-xs text-grey">{{$t('QTY')}}</p>
                    <p class="text-xs text-grey mt-1">{{$t('Price')}}</p>
                  </div>
                  <div class="col-span-2">
                    <p class="text-sm font-medium text-black">{{item.count}}</p>
                    <p class="text-sm font-medium text-black">{{item.unit_price === 0 ? $t('Free') : item.unit_price.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</p>
                  </div>
                </div>
                <feather-icon @click="removeSupply(item)" icon="Trash2Icon" svgClasses="w-6 h-8" class="cursor-pointer w-5 h-5 self-center text-red"/>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!isAndroid && packagesSize.filter(item => item.count > 0).length > 0 || (flag && packagesSize.filter(item => item.count > 0).length > 0)" class="px-2 absolute w-full" style="bottom: 126.5px;">
          <shipblu-card class="p-3">
            <p class="text-black mb-1">{{$t('Promo Code')}}</p>
            <input class="input-order w-full" type="text" v-validate="'max:30'" name="promo code" :placeholder="$t('If you have a promo code')"  v-model="discount_code">
          </shipblu-card>
        </div>
        <div @click="flag = !flag" class="total py-3 px-7 absolute bottom-0 w-full">
          <p class="text-darkblue font-semibold text-lg text-black">{{$t('Total')}}</p>
          <div class="flex items-center justify-between">
            <p class="text-black font-medium">{{(packagesSize.map(item => item.count).reduce((firstPackage, secondPackage) => Number(firstPackage) + Number(secondPackage), 0)).toLocaleString()}} {{$t('Item')}}</p>
            <p class="text-black">
              <span class="text-xl font-semibold">{{total ? total.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0.00'}}</span>
              <span class="font-medium">{{' ' + $t('EGP')}}</span>
            </p>
          </div>
          <div class="mt-2" v-if="merchantStatus === 'active' && packagesSize.filter(item => item.count > 0).length > 0">
            <template v-if="merchant.is_self_signup && (merchant.name === 'Entrepreneur Merchant' || (merchant.self_signup_verified === null && merchant.name !== 'Entrepreneur Merchant'))">
              <vx-tooltip position="top" color="warning" :text="merchant.name === 'Entrepreneur Merchant' ?`${$t('Your merchant profile is incomplete. Please complete your profile to request packaging material.')}` : `${$t('Your profile is still under review. You can receive your packaging material from our Warehouse!')}`">
                <vs-button @click="$emit('supplyRequest')" :disabled="merchant.name === 'Entrepreneur Merchant' ? true : false" class="bg-blue-900 w-full" v-if="!isAndroid">{{$t('Check out')}}</vs-button>
                <div v-else>
                  <vs-button class="bg-blue-900 w-full" :class="!flag ? 'block' : 'hidden'">{{$t('Check out')}}</vs-button>
                  <vs-button disabled="true" class="bg-blue-900 w-full" :class="flag ? 'block' : 'hidden'">{{$t('Confirm')}}</vs-button>
                </div>
              </vx-tooltip>
            </template>
            <template v-else>
              <vs-button @click="$emit('supplyRequest')" class="bg-blue-900 w-full" v-if="!isAndroid">{{$t('Check out')}}</vs-button>
              <div v-else>
                <vs-button class="bg-blue-900 w-full" :class="!flag ? 'block' : 'hidden'">{{$t('Check out')}}</vs-button>
                <vs-button @click="$emit('supplyRequest')" class="bg-blue-900 w-full" :class="flag ? 'block' : 'hidden'">{{$t('Confirm')}}</vs-button>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {sendRequest} from '../../http/axios/requestHelper'
import Other0 from '../../assets/images/pages/other2.png'
import Other1 from '../../assets/images/pages/other1.png'
import Other2 from '../../assets/images/pages/other0.png'
import Other3 from '../../assets/images/pages/other3.png'
import Other4 from '../../assets/images/pages/other4.png'
import ShipbluCard from '../../layouts/components/ShipbluCard.vue'

export default {
  props: ['merchantStatus', 'merchant', 'packagesSize', 'typeFilter'],
  data () {
    return {
      statusFilters: [
        {
          name: 'All',
          value: 'all'
        },
        {
          name: 'Flyer',
          value: 'flyer'
        },
        {
          name: 'Boxes',
          value: 'box'
        },
        {
          name: 'Other Items',
          value: 'other'
        }
      ],
      allFlag: true,
      flayerFlag: false,
      bosxFlag: false,
      otherFlag: false,
      flag : false,
      isAndroid: false,
      total: 0,
      windowWidth: 0,
      discount_code: '',
      Other0,
      Other1,
      Other2,
      Other3,
      Other4
    }
  },
  watch: {
    '$route.query.type' (val) {
      this.activeTabtype(val)
    },
    windowWidth () {
      if (this.windowWidth < 768) {
        this.isAndroid = true
      } else {
        this.isAndroid = false
        this.flag = false
      }
    },
    packagesSize: {
      handler () {
        this.applyPromoCode()
      },
      deep: true
    },
    discount_code () {
      this.applyPromoCode()
    }
  },
  methods: {
    addOne (supply) {
      supply.count = 0
      supply.count += 1
      this.packagesSize.sort((a, b) => a.id - b.id)
    },
    removeSupply (item) {
      item.count = 0
      this.packagesSize.sort((a, b) => a.id - b.id)
    },
    activeTabtype (type) {
      switch (type) {
      case 'all':
        this.allFlag = true
        this.flayerFlag = true
        this.bosxFlag = true
        this.otherFlag = true
        break
      case 'flyer':
        this.allFlag = false
        this.flayerFlag = true
        this.bosxFlag = false
        this.otherFlag = false
        break
      case 'box':
        this.allFlag = false
        this.flayerFlag = false
        this.bosxFlag = true
        this.otherFlag = false
        break
      case 'other':
        this.allFlag = false
        this.flayerFlag = false
        this.bosxFlag = false
        this.otherFlag = true
        break
      }
    },
    importImage (index) {
      let image = ''
      switch (index) {
      case 0:
        image = this.Other0
        break
      case 1:
        image = this.Other1
        break
      case 2:
        image = this.Other2
        break
      case 3:
        image = this.Other3
        break
      case 4:
        image = this.Other4
        break
      default:
        break
      }
      return image
    },
    applyPromoCode () {
      if (this.packagesSize.filter(item => item.count > 0).length > 0) {
        const obj = {
          packages_size: {},
          discount_code: null
        }
        const selectedPackages = this.packagesSize.filter(item => item.count > 0)
        selectedPackages.forEach(item => {
          obj.packages_size[item.id] = item.count
        })
        if (this.discount_code) {
          obj.discount_code = this.discount_code
        }
        sendRequest(false, false, this, 'api/v1/pricing/supplies-request/', 'post', obj, true,
          (response) => {
            this.total = response.data.total_price
          }
        )
      }
    }
  },
  components: {
    ShipbluCard
  },
  created () {
    this.activeTabtype(this.typeFilter)
    if (this.windowWidth < 768) {
      this.isAndroid = true
    } else {
      this.isAndroid = false
      this.flag = false
    }
    this.windowWidth = window.innerWidth
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  }
}
</script>