<template>
  <shipblu-prompt
    class="shipment-modal"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="selectLocationsModal"
    :title="$t('Your Locations')"
    :buttons-hidden="true">
    <div class="text-center">
      <p class="text-darkgray text-base mb-4">{{$t('Select your payment method.')}}</p>
      <div class="flex sm:mt-0 mt-6 items-center justify-center">
        <div v-for="(method, index) in paymentTypes" :key="index">
          <label @click="checkedItem(method, paymentTypes)" class="prompt cursor-pointer" :class="index === 0 ? 'mr-4' : ''">
            <input v-model="payment.pay_using" :class="payment.pay_using && payment.pay_using.checked === method.checked ? 'checked' : ''" :value="method" type="radio" />
            <span class="py-2 px-4 rounded text-sm">{{ method.name }}</span>
          </label>
        </div>
      </div>
    </div>
    <div class="vx-row mb-2 p-6 justify-content">
      <p class="font-medium text-xs">{{ $t('Select one of your locations') }}</p>
      <v-select :class="pickupPoint ? 'pickup-point-filled' : ''" class="pickup-point w-full" v-model="pickupPoint" v-validate="'required'"
        placeholder="Add pickup Point" name="pickup point" label="fullAddress" :options="pickupPoints"/>
      <span class="text-danger text-sm" v-show="errors.has('pickup point')">{{ errors.first('pickup point') }}</span>
    </div>
    <div class="grid grid-cols-1 gap-4 mt-4 items-center justify-content w-2/3 m-auto">
      <button :disabled="Object.keys(payment).length === 0" @click="sendLocation()" :class="Object.keys(payment).length === 0 ? 'disable-btn btn' : 'active-btn btn'" id="pay-btn-loading">{{ $t('Confirm') }}</button>
    </div>
  </shipblu-prompt>
</template>
<script>
import vSelect from 'vue-select'
import ShipbluPrompt from '../../layouts/components/ShipBluPrompt.vue'
import {sendRequest} from '../../http/axios/requestHelper'
import common from '../../assets/utils/common'

export default {
  props: ['selectLocationsModal', 'payment', 'paymentTypes'],
  data () {
    return {
      pickupPoint : {},
      pickupPoints: [],
      merchantID: ''
    }
  },
  components: {
    vSelect,
    ShipbluPrompt
  },
  methods: {
    checkedItem (type) {
      this.paymentTypes.forEach(item => {
        if (item.name === type.name) {
          item.checked = true
        } else {
          item.checked = false
        }
      })
    },
    sendLocation () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$emit('payment', this.payment)
          this.$emit('pickupPoint', this.pickupPoint)
          this.$emit('checkOut')
        }
      })
    },
    closeModal () {
      this.$emit('paymentTypes', this.paymentTypes)
      this.$emit('selectLocationsModal', false)
    },
    loadPickupPoints () {
      sendRequest(false, false, this, `api/v1/merchants/${this.merchantID}/pickup-points/`, 'get', null, true, 
        (response) => {
          this.pickupPoints = response.data.results
          this.pickupPoints.forEach(element => {
            element.fullAddress = element.address.line_1 + element.address.line_2 + element.address.zone.name
            if (element.is_default) {
              this.pickupPoint = element
            }
          })
        }
      )
    }
  },
  created () {
    const merchantData = common.checkMerchantInLocalStorage(this)
    let user = {}
    merchantData.then(results => {
      user = results.merchantData
      this.merchantID = user.id
      this.userMerchant = results.userMerchantData
      this.merchantStatus = user.status
      if (this.merchantStatus === 'active') {
        this.loadPickupPoints()
      }
    })
  }
}
</script>

<style lang='scss'>
</style>